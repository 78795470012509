<template>
  <van-pull-refresh
    v-model="isLoading"
    pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..."
    success-text="Refresh Berhasil"
    @refresh="onRefresh"
  >
    <div class="authentication">
      <BaseHeaderBar
        title="Verifikasi Data"
        :showCustomerService="false"
        backBtnType="1"
        :showBackButton="true"
        color="#FFF"
        bgColor="#2952E5"
      />
      <div class="content">
        <img
          class="authentication-icon"
          src="@/assets/img/authentication-icon.png"
        />
        <VueSlickCarousel
          :arrows="false"
          :dots="true"
          :centerMode="false"
          :infinite="true"
          dotsClass="slick-dots"
          :slidesToShow="1"
          :slidesToScroll="1"
          centerPadding="0px"
          :variableWidth="true"
        >
          <div :style="{ width: '4.627rem' }" class="slick-item">
            <div
              :class="[
                'authentication-item',
                { active: authInfo.iddrentityInfoStatus },
              ]"
              @click="goIdentity"
            >
              <img
                class="icon"
                :src="
                  require(`@/assets/img/authentication-icon1${
                    authInfo.iddrentityInfoStatus ? '-active' : ''
                  }.png`)
                "
              />
              <p class="label">Informasi Identitas</p>
              <p class="status">
                {{
                  authInfo.iddrentityInfoStatus
                    ? "Sudah Verifikasi"
                    : "Belum Verifikasi"
                }}
              </p>
            </div>
          </div>
          <div :style="{ width: '4.627rem' }" class="slick-item">
            <div
              :class="[
                'authentication-item',
                { active: authInfo.indrformationStatus },
              ]"
              @click="goBasicInfo"
            >
              <img
                class="icon"
                :src="
                  require(`@/assets/img/authentication-icon2${
                    authInfo.indrformationStatus ? '-active' : ''
                  }.png`)
                "
              />
              <p class="label">Infomasi Dasar</p>
              <p class="status">
                {{
                  authInfo.indrformationStatus
                    ? "Sudah Verifikasi"
                    : "Belum Verifikasi"
                }}
              </p>
            </div>
          </div>
          <div :style="{ width: '4.627rem' }" class="slick-item">
            <div
              :class="[
                'authentication-item',
                { active: authInfo.badrnkStatus },
              ]"
              @click="goBankInfo"
            >
              <img
                class="icon"
                :src="
                  require(`@/assets/img/authentication-icon3${
                    authInfo.badrnkStatus ? '-active' : ''
                  }.png`)
                "
              />
              <p class="label">Informasi Bank</p>
              <p class="status">
                {{
                  authInfo.badrnkStatus
                    ? "Sudah Verifikasi"
                    : "Belum Verifikasi"
                }}
              </p>
            </div>
          </div>
        </VueSlickCarousel>
        <van-checkbox v-model="checked">
          <div class="privacy">
            Mengkonfirmasi dan Menyetujui "<a @click.stop="goAgreement"
              >Perjanjian Pinjaman</a
            >"
          </div>
          <template #icon="props">
            <img
              class="check-icon"
              :src="
                require(`@/assets/img/checked-${
                  props.checked ? '' : 'no-'
                }icon.png`)
              "
            />
          </template>
        </van-checkbox>
      </div>
      <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
      <van-dialog
        v-model="visible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-tip" src="@/assets/img/location-tip.jpg" />
        <div class="location-confirm" @click="onLocationConfirm">
          <img src="@/assets/img/location-confirm.jpg" />
        </div>
      </van-dialog>
      <van-dialog
        v-model="denyVisible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-deny" src="@/assets/img/location-deny.jpg" />
        <div class="location-confirm" @click="onCloseDialog('denyVisible')">
          <img src="@/assets/img/location-confirm.jpg" />
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Toast, PullRefresh, Dialog } from 'vant'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'

Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Authentication',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component,
    VueSlickCarousel
  },
  data () {
    return {
      isLoading: false,
      authInfo: {},
      denyVisible: false,
      checked: true
    }
  },
  created () {
    this.getAuthInfo()
  },
  methods: {
    getAuthInfo (isRefresh) {
      request.post('audrthIdentityPage')
        .then(res => {
          console.log('信息认证', res.audrthList, JSON.stringify(res.audrthList))
          this.authInfo = res.audrthList
          isRefresh && (this.isLoading = false)
        })
    },
    goIdentity () {
      if (this.authInfo.iddrentityInfoStatus) {
        Toast('Anda sudah verifikasi data identitas diri')
        return
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/identity',
        query: {
          amount
        }
      })
    },
    goBasicInfo () {
      if (this.authInfo.indrformationStatus) {
        Toast('Anda sudah verifikasi data informasi dasar')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/basicInfo',
        query: {
          amount
        }
      })
    },
    goBankInfo () {
      if (this.authInfo.badrnkStatus) {
        Toast('Anda sudah verifikasi data bank')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/bankInfo',
        query: {
          type: 'auth',
          amount
        }
      })
    },
    goAgreement () {
      const { amount } = this.$route.query
      this.$router.push({
        path: '/agreement',
        query: {
          amount
        }
      })
    },
    validate () {
      if (!this.checked) {
        Toast('Mengkonfirmasi dan Menyetujui "Perjanjian Pinjaman"')
        return false
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      return true
    },
    onLocationConfirm () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log('position', position)
          this.reportLocation(position.coords).then(() => {
            this.submitOrder()
          })
        }, (err) => {
          console.log(err)
          switch (err.code) {
            case err.PERMISSION_DENIED:
              this.denyVisible = true
              this.hasDeny = true
              break
          }
        })
      }
      this.onCloseDialog()
    },
    onSubmit () {
      if (!this.validate()) return
      this.isReportLocation().then(res => {
        console.log(res.whetherGrab)
        if (res.whetherGrab === 1) {
          if (this.hasDeny) {
            this.denyVisible = true
          } else {
            this.visible = true
          }
        } else {
          this.submitOrder()
        }
      })
    },
    submitOrder () {
      if (this.flag) return
      this.flag = true
      const { amount } = this.$route.query
      const postData = {
        apdrplicationAmount: amount,
        prdromotionChannel: 'AppStore'
      }
      getMetadata()
        .then(res => {
          // 提交设备信息
          return request.post('deviceInformation', res)
        })
        .then(() => {
          // 提交订单
          return request.post('sudrbmitloanOrder', postData)
        })
        .then(() => {
          // 跳转到首页
          this.$router.push('/home')
        })
        .catch(error => {
          // 错误处理
          console.error(error)
        })
        .finally(() => {
          // 结束操作
          this.flag = false
        })
    },
    onRefresh () {
      this.getAuthInfo(true)
    },
    isReportLocation () {
      return request.post('posisyonnanEstime')
    },
    reportLocation (data = {}) {
      console.log(typeof data, data, 'location')
      const { longitude = 0, latitude = 0, position = '', address = '', country = '', provinces = '', city = '', county = '', street = '' } = data
      const postData = {
        usdrerLongitude: longitude,
        usdrerLatitude: latitude,
        usdrerPositioning: position,
        usdrerAddress: address,
        usdrerCountries: country,
        usdrerProvinces: provinces,
        usdrerCity: city,
        usdrerCounty: county,
        usdrerStreet: street,
        crdrawl_source: 1
      }
      return request.post('grdrabLocation', postData)
    }
  }
}
</script>

<style scoped lang="less">
.authentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @body-bg-color;
}

.content {
  margin-top: -88px;
  background: url(../assets/img/authentication-bg.png) no-repeat;
  background-size: 750px 778px;
}

.authentication-icon {
  width: 435px;
  height: 487px;
  margin: 142px auto 0;
}

.slick-item {
  display: block !important;
}

.authentication-item {
  width: 315px;
  height: 315px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-right: 32px;
  background-color: #fff;
  overflow: auto;

  &.active {
    background-color: #4744e5;

    p {
      color: #f9f9f9;
    }
  }

  .icon {
    margin: 65px auto 0;
  }

  &:nth-child(1) .icon {
    width: 126px;
    height: 102px;
  }

  &:nth-child(2) .icon {
    width: 126px;
    height: 98px;
  }

  &:nth-child(3) .icon {
    width: 95px;
    height: 95px;
  }

  p {
    margin-top: 11px;
    color: #9b9b9b;
    text-align: center;
  }

  .label {
    line-height: 33px;
    font-size: 24px;
  }

  .status {
    line-height: 25px;
    font-size: 18px;
  }
}

/deep/ .slick-dots {
  display: flex !important;
  justify-content: center;
  margin-top: 24px;
  transform: none;

  li {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #d8d8d8;
    cursor: pointer;

    &:nth-child(n + 2) {
      margin-left: 22px;
    }

    &.slick-active {
      background-color: #2952e5;
    }
  }

  button {
    font-size: 0;
    line-height: 0;
    color: transparent;
    outline: none;
    background: transparent;
  }
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-top: 20px;
  margin-left: 12px;
}

.privacy {
  line-height: 33px;
  margin-top: 24px;
  font-size: 24px;
  color: #333;

  a {
    color: #333;
    text-decoration: underline;
  }
}

.confirm-btn {
  .submit-btn;
  width: 690px;
  margin-top: 54px;
}

.location-tip {
  width: 100%;
  margin: 30px auto 0;
}

.location-confirm {
  width: 578px;
  height: 93px;
  margin: 40px auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.location-deny {
  width: calc(100% - 40px);
  margin: 30px auto 0;
}
</style>
