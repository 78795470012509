var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-pull-refresh',{attrs:{"pulling-text":"Tarik ke bawah untuk refresh...","loosing-text":"Lepaskan untuk refresh...","success-text":"Refresh Berhasil"},on:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span')]},proxy:true}]),model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',{staticClass:"authentication"},[_c('BaseHeaderBar',{attrs:{"title":"Verifikasi Data","showCustomerService":false,"backBtnType":"1","showBackButton":true,"color":"#FFF","bgColor":"#2952E5"}}),_c('div',{staticClass:"content"},[_c('img',{staticClass:"authentication-icon",attrs:{"src":require("@/assets/img/authentication-icon.png")}}),_c('VueSlickCarousel',{attrs:{"arrows":false,"dots":true,"centerMode":false,"infinite":true,"dotsClass":"slick-dots","slidesToShow":1,"slidesToScroll":1,"centerPadding":"0px","variableWidth":true}},[_c('div',{staticClass:"slick-item",style:({ width: '4.627rem' })},[_c('div',{class:[
              'authentication-item',
              { active: _vm.authInfo.iddrentityInfoStatus },
            ],on:{"click":_vm.goIdentity}},[_c('img',{staticClass:"icon",attrs:{"src":require(`@/assets/img/authentication-icon1${
                  _vm.authInfo.iddrentityInfoStatus ? '-active' : ''
                }.png`)}}),_c('p',{staticClass:"label"},[_vm._v("Informasi Identitas")]),_c('p',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.authInfo.iddrentityInfoStatus ? "Sudah Verifikasi" : "Belum Verifikasi")+" ")])])]),_c('div',{staticClass:"slick-item",style:({ width: '4.627rem' })},[_c('div',{class:[
              'authentication-item',
              { active: _vm.authInfo.indrformationStatus },
            ],on:{"click":_vm.goBasicInfo}},[_c('img',{staticClass:"icon",attrs:{"src":require(`@/assets/img/authentication-icon2${
                  _vm.authInfo.indrformationStatus ? '-active' : ''
                }.png`)}}),_c('p',{staticClass:"label"},[_vm._v("Infomasi Dasar")]),_c('p',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.authInfo.indrformationStatus ? "Sudah Verifikasi" : "Belum Verifikasi")+" ")])])]),_c('div',{staticClass:"slick-item",style:({ width: '4.627rem' })},[_c('div',{class:[
              'authentication-item',
              { active: _vm.authInfo.badrnkStatus },
            ],on:{"click":_vm.goBankInfo}},[_c('img',{staticClass:"icon",attrs:{"src":require(`@/assets/img/authentication-icon3${
                  _vm.authInfo.badrnkStatus ? '-active' : ''
                }.png`)}}),_c('p',{staticClass:"label"},[_vm._v("Informasi Bank")]),_c('p',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.authInfo.badrnkStatus ? "Sudah Verifikasi" : "Belum Verifikasi")+" ")])])])]),_c('van-checkbox',{scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"check-icon",attrs:{"src":require(`@/assets/img/checked-${
                props.checked ? '' : 'no-'
              }icon.png`)}})]}}]),model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('div',{staticClass:"privacy"},[_vm._v(" Mengkonfirmasi dan Menyetujui \""),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.goAgreement.apply(null, arguments)}}},[_vm._v("Perjanjian Pinjaman")]),_vm._v("\" ")])])],1),_c('button',{staticClass:"confirm-btn",attrs:{"type":"button"},on:{"click":_vm.onSubmit}},[_vm._v("Kirim")]),_c('van-dialog',{attrs:{"showConfirmButton":false,"close-on-click-overlay":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('img',{staticClass:"location-tip",attrs:{"src":require("@/assets/img/location-tip.jpg")}}),_c('div',{staticClass:"location-confirm",on:{"click":_vm.onLocationConfirm}},[_c('img',{attrs:{"src":require("@/assets/img/location-confirm.jpg")}})])]),_c('van-dialog',{attrs:{"showConfirmButton":false,"close-on-click-overlay":""},model:{value:(_vm.denyVisible),callback:function ($$v) {_vm.denyVisible=$$v},expression:"denyVisible"}},[_c('img',{staticClass:"location-deny",attrs:{"src":require("@/assets/img/location-deny.jpg")}}),_c('div',{staticClass:"location-confirm",on:{"click":function($event){return _vm.onCloseDialog('denyVisible')}}},[_c('img',{attrs:{"src":require("@/assets/img/location-confirm.jpg")}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }